import React, { useState, useEffect } from 'react';
import { BsInstagram } from "react-icons/bs";
import { FiFacebook } from "react-icons/fi";
import { FiPhone } from 'react-icons/fi';
import { MdStar, MdStarBorder } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { formatPhoneNumber } from '../../helpers';
import { VerifiedBadge } from './VerifiedBadge';
import '../../stylesheets/businessinfobanner.css';

function parseToRatingFormat(number) {
    // Round the number to one decimal place
    const roundedNumber = Math.round(number * 10) / 10;
  
    // Convert the number to a string
    const ratingString = roundedNumber.toFixed(1);

    return ratingString;
}

function LikeIcon(props) {
    const liked = <MdStar color='rgb(255, 202, 82)' size={16}/>
    const unliked = <MdStarBorder color='rgb(255, 202, 82)' size={16}/>

    let status = props.liked ? liked : unliked;

    return (status);
}

export function BusinessInfoBanner(props) {

    const business_id = props.business_id;
    const name = props.name;
    const phone = props.phone;
    const facebook = props.facebook;
    const instagram = props.instagram;
    const [thumbnail, setThumbnail] = useState("");
    const [rating, setRating] = useState(0);
    const [fullRating, setFullRating] = useState([]);
    const [numOfReviews, setNumOfReviews] = useState(0);
    const [isVerified, setIsVerified] = useState(false);

    function getVerificationStatus() {
        fetch(`https://coorly-server.onrender.com/business/verification/status/${business_id}`)
        .then((res) => res.json())
        .then((data) => {
            setIsVerified(data[0].isVerified);
        })
    }

    function getThumbnail() {
        fetch(`https://coorly-server.onrender.com/business/thumbnail/${business_id}`)
        .then((res) => res.json())
        .then((data) => {
            setThumbnail(data[0].url);
        })
        .catch((err) => console.log(err))
    }

    function getRating() {
        let overallRating = 0;

        fetch(`https://coorly-server.onrender.com/business/reviews/${business_id}`)
        .then(response => response.json())
        .then((data) => {
            for (let i = 0; i < data.length; i++) {
                overallRating += data[i].rating;
            }

            let arr = [];

            const rating = overallRating / data.length;

            for (let i = 0; i < 5; i++) {
                if (i < Math.floor(rating)) {
                    arr.push(<LikeIcon liked/>);
                }
                else {
                    arr.push(<LikeIcon />);
                }
            }
            setNumOfReviews(data.length)
            setFullRating(arr)
            setRating(parseToRatingFormat(rating))
        })
        .catch(err => console.log(err))
    }

    useEffect(() => {
        getVerificationStatus();
        getThumbnail();
        getRating();
    }, [])

    return (
            <div className='business-info-banner'>
                <div style={{display: 'flex', flexDirection: 'row', width: facebook || instagram ? '65%' : '100%', alignItems: 'center'}}>
                    <img src={thumbnail} style={{height: '3.25rem', width: '3.5rem'}}/>
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-around', marginLeft: '1rem'}}>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            {
                                isVerified
                                ?
                                <VerifiedBadge small/>
                                :
                                null
                            }
                            <p>{name}</p>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <FiPhone size={14}/>
                            <Link to={`tel:${phone}`} className='banner-phone'>
                                <p style={{fontSize: 14, marginLeft: 4}}>{formatPhoneNumber(phone)}</p>
                            </Link>
                        </div>
                        <div className='banner-star-container'>
                            {
                                numOfReviews > 0
                                ?
                                <>
                                    {fullRating}
                                    <p style={{fontSize: 12, marginLeft: '.5rem', fontFamily: 'Gilroy-Medium', color: 'rgb(50, 50, 50)', marginBottom: -4}}>{rating}({numOfReviews})</p>
                                </>
                                :
                                <p style={{fontFamily: 'Gilroy-Medium', color: 'rgb(50, 50, 50)'}}>No ratings yet!</p>
                            }
                        </div>
                    </div>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                    {
                        instagram
                        ?
                        <Link to={`https://www.instagram.com/${instagram}`} className="banner-social-media-link">
                            <BsInstagram color='white' size={16}/>
                        </Link>
                        :
                        null
                    }
                    {
                        facebook
                        ?
                        <Link to={facebook} className="banner-social-media-link">
                            <FiFacebook color='white' size={16}/>
                        </Link>
                        :
                        null
                    }
                </div>
            </div>
    );
}