export async function getClientId(business_id, phone) {
    return fetch(`https://coorly-server.onrender.com/business/client/check/businessid=${business_id}&phone=${phone}`)
    .then((res) => res.json())
    .then((data) => {
        return data[0].client_id;
    })
    .catch((err) => console.log(err))
}

export async function getClientName(client_id) {
    return fetch(`https://coorly-server.onrender.com/business/client/${client_id}`)
    .then((res) => res.json())
    .then((data) => {
        return data[0].name;
    })
    .catch((err) => console.log(err))
}

export async function getClientPhone(client_id) {
    return fetch(`https://coorly-server.onrender.com/business/client/${client_id}`)
    .then((res) => res.json())
    .then((data) => {
        return data[0].phone;
    })
    .catch((err) => console.log(err))
}