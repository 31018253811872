import { useState, useEffect } from 'react';
import { getDayOfWeek } from '../helpers';
import '../stylesheets/hours.css';


export function Hours(props) {

    const business_id = props.business_id
    const [sunday, setSunday] = useState([null, null])
    const [monday, setMonday] = useState([null, null])
    const [tuesday, setTuesday] = useState([null, null])
    const [wednesday, setWednesday] = useState([null, null])
    const [thursday, setThursday] = useState([null, null])
    const [friday, setFriday] = useState([null, null])
    const [saturday, setSaturday] = useState([null, null])
    

    useEffect(() => {
        fetch(`https://coorly-server.onrender.com/business/schedule/${business_id}`)
        .then(response => response.json())
        .then((data) => {
            setSunday([data[0].sundayStart, data[0].sundayEnd])
            setMonday([data[0].mondayStart, data[0].mondayEnd])
            setTuesday([data[0].tuesdayStart, data[0].tuesdayEnd])
            setWednesday([data[0].wednesdayStart, data[0].wednesdayEnd])
            setThursday([data[0].thursdayStart, data[0].thursdayEnd])
            setFriday([data[0].fridayStart, data[0].fridayEnd])
            setSaturday([data[0].saturdayStart, data[0].saturdayEnd])
        })
        .catch(err => console.log(err))
    }, [])

    return (
        <div className="container">
            <div className="day-container">
                <p className='day' style={getDayOfWeek(new Date) == 'sunday' ? {fontFamily: 'Gilroy-Medium', textDecorationLine: 'underline'} : {}}>Sunday</p>
                {
                    sunday[0]
                    ?
                    <p className='day' style={getDayOfWeek(new Date) == 'sunday' ? {fontFamily: 'Gilroy-Medium', textDecorationLine: 'underline'} : {}}>{new Date(sunday[0]).toLocaleTimeString().replace(":00 ", "")}-{new Date(sunday[1]).toLocaleTimeString().replace(":00 ", "")}</p>
                    :
                    <p className='day' style={getDayOfWeek(new Date) == 'sunday' ? {fontFamily: 'Gilroy-Medium', textDecorationLine: 'underline'} : {}}>Closed</p>
                }
                
            </div>
            <div className="day-container">
                <p className='day' style={getDayOfWeek(new Date) == 'monday' ? {fontFamily: 'Gilroy-Medium', textDecorationLine: 'underline'} : {}}>Monday</p>
                {
                    monday[0]
                    ?
                    <p className='day' style={getDayOfWeek(new Date) == 'monday' ? {fontFamily: 'Gilroy-Medium', textDecorationLine: 'underline'} : {}}>{new Date(monday[0]).toLocaleTimeString().replace(":00 ", "")}-{new Date(monday[1]).toLocaleTimeString().replace(":00 ", "")}</p>
                    :
                    <p className='day' style={getDayOfWeek(new Date) == 'monday' ? {fontFamily: 'Gilroy-Medium', textDecorationLine: 'underline'} : {}}>Closed</p>
                }
            </div>
            <div className="day-container">
                <p className='day' style={getDayOfWeek(new Date) == 'tuesday' ? {fontFamily: 'Gilroy-Medium', textDecorationLine: 'underline'} : {}}>Tuesday</p>
                {
                    tuesday[0]
                    ?
                    <p className='day' style={getDayOfWeek(new Date) == 'tuesday' ? {fontFamily: 'Gilroy-Medium', textDecorationLine: 'underline'} : {}}>{new Date(tuesday[0]).toLocaleTimeString().replace(":00 ", "")}-{new Date(tuesday[1]).toLocaleTimeString().replace(":00 ", "")}</p>
                    :
                    <p className='day' style={getDayOfWeek(new Date) == 'tuesday' ? {fontFamily: 'Gilroy-Medium', textDecorationLine: 'underline'} : {}}>Closed</p>
                }
            </div>
            <div className="day-container">
                <p className='day' style={getDayOfWeek(new Date) == 'wednesday' ? {fontFamily: 'Gilroy-Medium', textDecorationLine: 'underline'} : {}}>Wednesday</p>
                {
                    wednesday[0]
                    ?
                    <p className='day' style={getDayOfWeek(new Date) == 'wednesday' ? {fontFamily: 'Gilroy-Medium', textDecorationLine: 'underline'} : {}}>{new Date(wednesday[0]).toLocaleTimeString().replace(":00 ", "")}-{new Date(wednesday[1]).toLocaleTimeString().replace(":00 ", "")}</p>
                    :
                    <p className='day' style={getDayOfWeek(new Date) == 'wednesday' ? {fontFamily: 'Gilroy-Medium', textDecorationLine: 'underline'} : {}}>Closed</p>
                }
            </div>
            <div className="day-container">
                <p className='day' style={getDayOfWeek(new Date) == 'thursday' ? {fontFamily: 'Gilroy-Medium', textDecorationLine: 'underline'} : {}}>Thursday</p>
                {
                    thursday[0]
                    ?
                    <p className='day' style={getDayOfWeek(new Date) == 'thursday' ? {fontFamily: 'Gilroy-Medium', textDecorationLine: 'underline'} : {}}>{new Date(thursday[0]).toLocaleTimeString().replace(":00 ", "")}-{new Date(thursday[1]).toLocaleTimeString().replace(":00 ", "")}</p>
                    :
                    <p className='day' style={getDayOfWeek(new Date) == 'thursday' ? {fontFamily: 'Gilroy-Medium', textDecorationLine: 'underline'} : {}}>Closed</p>
                }
            </div>
            <div className="day-container">
                <p className='day' style={getDayOfWeek(new Date) == 'friday' ? {fontFamily: 'Gilroy-Medium', textDecorationLine: 'underline'} : {}}>Friday</p>
                {
                    friday[0]
                    ?
                    <p className='day' style={getDayOfWeek(new Date) == 'friday' ? {fontFamily: 'Gilroy-Medium', textDecorationLine: 'underline'} : {}}>{new Date(friday[0]).toLocaleTimeString().replace(":00 ", "")}-{new Date(friday[1]).toLocaleTimeString().replace(":00 ", "")}</p>
                    :
                    <p className='day' style={getDayOfWeek(new Date) == 'friday' ? {fontFamily: 'Gilroy-Medium', textDecorationLine: 'underline'} : {}}>Closed</p>
                }
            </div>
            <div className="day-container">
                <p className='day' style={getDayOfWeek(new Date) == 'saturday' ? {fontFamily: 'Gilroy-Medium', textDecorationLine: 'underline'} : {}}>Saturday</p>
                {
                    saturday[0]
                    ?
                    <p className='day' style={getDayOfWeek(new Date) == 'saturday' ? {fontFamily: 'Gilroy-Medium', textDecorationLine: 'underline'} : {}}>{new Date(saturday[0]).toLocaleTimeString().replace(":00 ", "")}-{new Date(saturday[1]).toLocaleTimeString().replace(":00 ", "")}</p>
                    :
                    <p className='day' style={getDayOfWeek(new Date) == 'saturday' ? {fontFamily: 'Gilroy-Medium', textDecorationLine: 'underline'} : {}}>Closed</p>
                }
            </div>
        </div>
    );
}