import React from 'react';
import { Link } from 'react-router-dom';
import { SearchBar } from '../components/SearchBar';
import '../stylesheets/header.css'

export function Header(props) {

    const isHomePage = props.isHomePage;
    const isSearch = props.isSearch;

    if (isHomePage) {
        return (
            <header id="home-page-header">
                <div id="main-header">
                    <Link id="header-text" to="/">
                        <img style={{height: '4rem'}} src={process.env.PUBLIC_URL + '/media/Coorly Full White.png'}/>
                    </Link>
                    <div className="header-links-container" id="home-header">
                        <Link id="header-link-boxed" to="https://biz.coorly.app/">Coorly for Businesses</Link>
                    </div>
                </div>
            </header>
        );
    } else if (isSearch) {
        return (
            <header id="company-header">
                <div id="main-header">
                    <Link id="header-text" to="/">
                        <img style={{height: '4rem'}} src={process.env.PUBLIC_URL + '/media/Coorly Full White.png'}/>
                    </Link>
                    <div id='mobile-check'>
                        <SearchBar />
                    </div>
                </div>
            </header>
        );
    } else {
        return (
            <header id="company-header">
                <div id="main-header">
                    <Link id="header-text" to="/">
                        <img style={{height: '4rem'}} src={process.env.PUBLIC_URL + '/media/Coorly Full White.png'}/>
                    </Link>
                </div>
            </header>
        );
    }
}