import React, {useEffect, useState} from 'react';
import { Footer } from '../components/Footer';
import { Header } from '../components/Header';
import { SearchBar } from '../components/SearchBar';
import { CompanyBox } from '../components/CompanyBox';
import CookieConsent from "react-cookie-consent";
import { Link } from 'react-router-dom';
import { MdArrowRightAlt } from 'react-icons/md';
import { LocationServicesBanner } from '../components/elements/LocationServicesBanner';
import '../stylesheets/root.css'

export function Root() {

    useEffect(() => {
        document.title = 'Coorly - Find mobile auto detailers near you... 24/7 Online booking';
    }, [])

    const [discover, setDiscover] = useState([]);
    const [state, setState] = useState("");
    const [state_long_name, setState_Long_Name] = useState("");

    async function reverseGeocodeToGetClientState(lat, lon) {
        try {
            const res = await fetch(`https://coorly-server.onrender.com/google/maps/reversegeolocation/${lat}&${lon}&result_type=administrative_area_level_1`);
            const data = await res.json();
            const { short_name, long_name } = data.results[0].address_components[0];
            setState(short_name);
            setState_Long_Name(long_name);
        } catch (err) {
            console.log(err);
        }
    }

    function getLocation() {
        navigator.geolocation.getCurrentPosition((success, error) => {
            if (error) return; 
            const lat = success.coords.latitude;
            const lon = success.coords.longitude;
            reverseGeocodeToGetClientState(lat, lon);
        })
    }

    async function getDiscover(state) {
        fetch(`https://coorly-server.onrender.com/home/discover/${state}`)
        .then((res) => res.json())
        .then((data) => {
            let results = [];
            for (let i = 0; i < data.length; i++) {
                results.push(<CompanyBox business_id={data[i].business_id}/>)
            }
            setDiscover(results);
        })
        .catch((err) => console.log("Discovery : ", err))
    }

    useEffect(() => {
        getDiscover(state);
    }, [state])

    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', overflowX: 'hidden'}}>
            <CookieConsent location="bottom" style={{ background: "#4169E1", fontFamily: 'Gilroy-Medium' }} buttonStyle={{ color: "#4169e1", backgroundColor: 'white', borderWidth: 1, fontSize: "13px", fontFamily: 'Gilroy-Medium'}}>
                This website uses cookies to enhance the user experience.
            </CookieConsent>
            <div className='video-container'>
                <div className="overlay">
                    <Header isHomePage/>
                    <p>Discover & book local auto detailers</p>
                    <div id="search-container">
                        <SearchBar />
                    </div>
                    <Link className="business-redirect" to="https://biz.coorly.app/">
                        <MdArrowRightAlt color='white' size={32}/>
                        <p>Do you run a mobile detailing business? Click here!</p>
                    </Link>
                </div>
            </div>
            <div className='home-container'>
                <div className="homepage-section" id="discovery">
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <p className="homepage-section-header">{ state ? `Recommended in ${state_long_name}` : "Featured"}</p>
                    </div>
                    <div className='companybox-row'>
                        {
                            discover.length > 0
                            ?
                            discover
                            :
                            <p>There are currently no detailers available near you</p>
                        }
                    </div>
                </div>
                <LocationServicesBanner getLocation={getLocation}/>
            </div>
            <Footer />
        </div>
    )
}