import { useNavigate } from 'react-router-dom';
import '../stylesheets/errorpage.css'
import { useEffect } from "react";

export function ErrorPage() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/');
  }, [])

  return (
    <div>
    </div>
  );
}