import React from 'react';
import { Link } from 'react-router-dom';
//import '../stylesheets/footer.css'
import {default as Feet} from 'rc-footer';
import 'rc-footer/assets/index.css';

export function Footer(props) {
    return(
        <Feet
            columns={[
            {
                icon: (<img src={process.env.PUBLIC_URL + '/media/New Coorly White Transparent.png'}/>),
                title: 'Coorly',
                url: 'https://coorly.app/',
                openExternal: true,
            },
            {
                title: 'General',
                items: [
                  {
                    title: 'Contact Us',
                    url: 'https://coorly.app/info/contact/',
                    openExternal: true,
                  },
                  {
                    title: 'Terms of Service',
                    url: 'https://app.termly.io/document/terms-and-conditions/4bbca192-0d93-4d7b-a3e0-3ae01f4353af',
                    openExternal: true,
                  },
                  {
                    title: 'Privacy Policy',
                    url: 'https://app.termly.io/document/privacy-policy/c82a1ae8-817b-4556-b79d-07addda0d92f',
                    openExternal: true,
                  },
                ],
              },
              {
                title: 'Social Media',
                items: [
                  {
                    title: 'Facebook',
                    url: 'https://www.facebook.com/profile.php?id=100094715245739',
                    openExternal: true,
                  },
                  {
                    title: 'Instagram',
                    url: 'https://www.instagram.com/coorlyapp/',
                    openExternal: true,
                  },
                ],
              },
            ]}
            style={{width: '100%', marginTop: '2rem'}}
            backgroundColor='#4169E1'
        />
    )
}