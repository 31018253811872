import React, { useEffect } from 'react';
import '../../stylesheets/additionalservice.css';
import { toTitleCase } from '../../helpers';
import { FiChevronsRight } from 'react-icons/fi';

const times = {
    15 : "15min",
    30 : "30min",
    45 : "45min",
    60 : "1 hour",
    75 : "1h 15min",
    90 : "1h 30min",
    105 : "1h 45min",
    120 : "2 hours",
    135 : "2h 15min",
    150 :"2h 30min",
    165 : "2h 45min",
    180 : "3 hours",
    195 : "3h 15min",
    210 : "3h 30min",
    225 : "3h 45min",
    240 : "4 hours",
    255 : "4h 15min",
    270 : "4h 30min",
    285 : "4h 45min",
    300 : "5 hours"
}

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

export function AdditionalService(props) {
    
    const isSelected = props.isSelected;
    const name = props.name;
    const price = props.price;
    const time = props.avgTime;
    const services = props.services.split("|");
    const type = props.type;

    return (
        <div className='additional-service-box' style={{borderBottomColor: isSelected ? '#4CAF50' : '#4169e1'}}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                <div>
                    <p id='service-name'>{name}</p>
                    <p id='service-name'>{formatter.format(price)} • {times[time]} • {type == "all" ? "All Vehicles" : `${toTitleCase(type)}s`}</p>
                </div>
            </div>
            <p id='service-price'>What's Included:</p>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                <div style={{width: '100%'}}>
                    {
                        services.map((service) => {
                            return(
                                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                    <FiChevronsRight color='#4169e1' size={18}/>
                                    <p id='service-desc'>{service}</p>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    );
}