import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Root } from './pages/Root';
import { ErrorPage } from './pages/ErrorPage';
import { BusinessPage } from './pages/BusinessPage';
import { BookPage } from './pages/BookPage';
import { SearchPage } from './pages/SearchPage';
import { ConfirmationPage } from './pages/ConfirmationPage';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import './index.css';

TimeAgo.addDefaultLocale(en);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Root />}/>
        <Route path='/business/:business_id/:businessName' element={<BusinessPage />}/>
        <Route path='/business/:business_id/book' element={<BookPage />}/>
        <Route path='/appointment/:business_id/confirmation/:appointmentId' element={<ConfirmationPage />}/>
        <Route path='/search/:query?/:city?' element={<SearchPage />}/>
        <Route path='*' element={<ErrorPage />}/>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);