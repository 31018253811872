import React from 'react';
import { InfinitySpin } from 'react-loader-spinner'
import '../stylesheets/splashscreen.css'

export function Splashscreen() {
    return (
        <div className='coorly-title'>
            <h1>Coorly</h1>
            <InfinitySpin 
                width='200'
                color="#4169E1"
            />
        </div>
    )
}