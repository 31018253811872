import React, {useState, useEffect} from 'react';
import { MdStar } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { VerifiedBadge } from './elements/VerifiedBadge';
import { FiClock } from 'react-icons/fi';
import '../stylesheets/companybox.css'

function LikeIcon() {
    return (
        <MdStar
            color='rgb(255, 202, 82)'
            size={20}
            style={{marginRight: '4px'}}
        />
    );
}

function formatRating(number) {
    // Round the number to one decimal place
    const roundedNumber = Math.round(number * 10) / 10;
    
    // Convert the number to a string
    const ratingString = roundedNumber.toString();
    
    // If the number has no decimal part, add '.0' at the end
    if (ratingString.indexOf('.') === -1) {
      return ratingString + '.0';
    }
    
    return ratingString;
}

function RightClockIcon() {
    return (
        <FiClock
            color='rgb(255, 202, 82)'
            size={16}
            style={{marginLeft: 4}}
        />
    )
}

function LeftClockIcon() {
    return (
        <FiClock
            color='rgb(255, 202, 82)'
            size={16}
            style={{marginRight: 4}}
        />
    )
}

export function CompanyBox(props) {

    const business_id = props.business_id;
    const [name, setName] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [rating, setRating] = useState("");
    const [ratingCount, setRatingCount] = useState("");
    const [thumbnail, setThumbnail] = useState("");
    const [isVerified, setIsVerified] = useState(false);
    const [doesSameDay, setDoesSameDay] = useState(false);

    function doesSameDayAppointments() {
        fetch(`https://coorly-server.onrender.com/business/schedule/isSameDayAllowed/${business_id}`)
        .then((res) => res.json())
        .then((data) => {
            setDoesSameDay(data[0].isSameDayAllowed);
        })
    }

    function getVerificationStatus() {
        fetch(`https://coorly-server.onrender.com/business/verification/status/${business_id}`)
        .then((res) => res.json())
        .then((data) => {
            setIsVerified(data[0].isVerified);
        })
    }

    function getInfo() {
        fetch(`https://coorly-server.onrender.com/business/info/${business_id}`)
        .then((res) => res.json())
        .then((data) => {
            setName(data[0][0].name)
            setCity(data[0][0].address.split(", ")[1]) 
            setState(String(data[0][0].address.split(", ")[2]).split(" ")[0]) 
            if (data[1][0]) {
                setThumbnail(data[1][0].url)
            } else {
                setThumbnail('https://res.cloudinary.com/doopnoeyc/image/upload/v1682276487/logos/alt_coorly_logo_ddhd4b.png')
            }
        })
        .catch(err => console.log("Companybox: ", err))
    }

    function getRating() {
        fetch(`https://coorly-server.onrender.com/business/reviews/overallrating/${business_id}`)
        .then((res) => res.json())
        .then((data) => {
            setRating(Number(data[0].rating));
            setRatingCount(data[0].count);
        })
    }

    useEffect(() => {
        doesSameDayAppointments();
        getVerificationStatus();
        getInfo();
        getRating();
    }, [business_id])

    return (
        <Link to={`/business/${business_id}/${name.replace(" ", "-")}`} className="new-companybox-link" style={{backgroundImage: `linear-gradient(to top, rgba(0,0,0,.75), rgba(0,0,0,0)), url(${thumbnail})`}}>
            <div id="type-container">
                {
                    isVerified
                    ?
                    <VerifiedBadge/>
                    :
                    null
                }
            </div>
            <div style={{ margin: '1rem' }}>
                <p className='new-companybox-name'>{name}</p>
                <p className='new-companybox-location'>{city}, {state}</p>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end'}}>
                    {
                        rating
                        ?
                        <div className='new-companybox-ratings-container'>
                            <LikeIcon />
                            <p className='new-companybox-rating'>{`${formatRating(rating)} (${ratingCount})`}</p>
                        </div>
                        :
                        null
                    }
                    {
                        doesSameDay
                        ?
                            rating
                            ?
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                <p style={{fontFamily: 'Gilroy-Bold', color: 'white', fontSize: 12, margin: 0}}>Same day appointments</p>
                                <RightClockIcon/>
                            </div>
                            :
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                <LeftClockIcon/>
                                <p style={{fontFamily: 'Gilroy-Bold', color: 'white', fontSize: 12, margin: 0}}>Same day appointments</p>
                            </div>
                        :
                        null
                    }
                </div>
            </div>
        </Link>
    );
}