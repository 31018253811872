import React, {useState, useEffect} from 'react';
import ResponsiveGallery from 'react-responsive-gallery';
import '../stylesheets/business.css';

export function Images(props) {

    const business_id = props.business_id;
    const [images, setImages] = useState([]);

    useEffect(() => {
        fetch(`https://coorly-server.onrender.com/business/portfolio/${business_id}`)
        .then((res) => res.json())
        .then((data) => {
            let arr = [];
            for (let i = 0; i < data.length; i++) {
                arr.push({
                    src: data[i].url
                });
            }
            setImages(arr);
        })
        .catch((err) => console.log(err))
    }, [])

    if (images) {
        return (
            <div className="image-container" style={{display: images.length == 0 ? 'none' : null}}>
                <div style={{width: '100%'}}>
                    <h2 style={{marginBottom: 0}}>Portfolio</h2>
                    <p style={{fontSize: '.75rem', fontFamily: 'Gilroy-Medium', marginTop: 0}}>Click to view</p>
                </div>
                <ResponsiveGallery 
                    images={images}
                    imagesMaxWidth={{xs: 100, s: 100, m: 100, l: 100, xl: 100, xxl:100}}
                    numOfImagesPerRow={{xs: 4, s: 4, m: 4, l: 5, xl: 5, xxl:5}}
                    useLightBox
                />
            </div>
        )
    }
}