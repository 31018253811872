import React, {useState, useEffect} from 'react';
import { MdStar, MdStarBorder } from 'react-icons/md';
import { getClientId, getClientName } from '../../API/clients';
import '../../stylesheets/ratingscard.css';
import '../../stylesheets/leavereview.css';

function parseToRatingFormat(number) {
    // Round the number to one decimal place
    const roundedNumber = Math.round(number * 10) / 10;
  
    // Convert the number to a string
    const ratingString = roundedNumber.toFixed(1);

    return ratingString;
}

function LikeIcon(props) {
    const liked = <MdStar color='rgb(255, 202, 82)' size={24}/>
    const unliked = <MdStarBorder color='rgb(255, 202, 82)' size={24}/>

    let status = props.liked ? liked : unliked;

    return (status);
}

async function getBusinessPushTokens(business_id) {
    return fetch(`https://coorly-server.onrender.com/getbusinesspushtokens/${business_id}`)
    .then((res) => res.json())
    .then((data) => {
        const tokens = data.map((x) => {
            return (x.token);
        });

        return (tokens);
    })
}

async function sendReviewRecievedNotification(tokens, name, rating) {
    const message = `${name} has just left a ${rating} ⭐ review. Click here to view!`;

    const formData = new FormData();

    formData.append('title', "Coorly for Businesses");
    formData.append('body', message);
    formData.append('tokens', tokens);

    const config = {
        method: 'POST',
        body: formData,
    };

    fetch(`https://coorly-server.onrender.com/sendpushnotification/`, config);
}

async function sendReview(business_id, client_id, review, rating) {
    const formData = new FormData();

    formData.append('business_id', business_id);
    formData.append('client_id', client_id);
    formData.append('review', review);
    formData.append('rating', rating);

    const config = {
        method: 'POST',
        body: formData,
    };

    return fetch(`https://coorly-server.onrender.com/business/addreview/`, config)
}

export function RatingsCard(props) {
    
    const business_id = props.business_id;

    const [rating, setRating] = useState(0);
    const [fullRating, setFullRating] = useState([]);
    const [numOfReviews, setNumOfReviews] = useState(0);
    const [mode, setMode] = useState(0); //0 for ratings, 1 for leave review
    const [message, setMessage] = useState("");
    
    const [fiveStars, setFiveStars] = useState(0);
    const [fourStars, setFourStars] = useState(0);
    const [threeStars, setThreeStars] = useState(0);
    const [twoStars, setTwoStars] = useState(0);
    const [oneStars, setOneStars] = useState(0);

    const [review, setReview] = useState("");
    const [phone, setPhone] = useState("");
    const [leaveRating, setLeaveRating] = useState(0);

    useEffect(() => {
        let overallRating = 0;
        
        let fives = 0;
        let fours = 0;
        let threes = 0;
        let twos = 0;
        let ones = 0;

        fetch(`https://coorly-server.onrender.com/business/reviews/${business_id}`)
        .then(response => response.json())
        .then((data) => {
            for (let i = 0; i < data.length; i++) {
                overallRating += data[i].rating;

                switch (data[i].rating) {
                    case 5:
                        fives++
                        break;
                    case 4:
                        fours++
                        break;
                    case 3:
                        threes++
                        break;
                    case 2:
                        twos++
                        break;
                    case 1:
                        ones++
                        break;
                    default:
                        break;
                }
            }

            let arr = [];

            const rating = overallRating / data.length;

            for (let i = 0; i < 5; i++) {
                if (i < Math.floor(rating)) {
                    arr.push(<LikeIcon liked/>);
                }
                else {
                    arr.push(<LikeIcon />);
                }
            }
            setNumOfReviews(data.length)
            setFullRating(arr)
            setRating(parseToRatingFormat(rating))

            setFiveStars(fives);
            setFourStars(fours);
            setThreeStars(threes);
            setTwoStars(twos);
            setOneStars(ones);
        })
        .catch(err => console.log(err))
    }, [])

    async function submitReview() {

        const r = review.trim();
        const p = phone.trim();

        try {
            if (r.length > 428) { throw new Error("Review must be less than 428 characters.") }
            if (p == "") { throw new Error("Please enter your phone number.") }
            if (leaveRating <= 0) { throw new Error("Please select a star rating.") }

            const client_id = await getClientId(business_id, p);
            if (!client_id) { throw new Error("Reviews can only be made with the phone number you previously booked this business with.") }

            await sendReview(business_id, client_id, r, leaveRating);

            const name = await getClientName(client_id);
            const tokens = await getBusinessPushTokens(business_id);
            sendReviewRecievedNotification(tokens, name, leaveRating);
            setMode(0);
        } catch (err) {
            setMessage(err.message);
        }
    }

    if (mode == 0) {
        return (
            <div className='ratings-card'>
                <p id='ratings-card-header'>Client Ratings</p>
                <div className='main-rating-container'>
                    <div className='main-star-container'>
                        {
                            numOfReviews > 0
                            ?
                            <>
                                {fullRating}
                                <p style={{marginLeft: '.5rem', fontFamily: 'Gilroy-Medium', color: 'rgb(50, 50, 50)'}}>{rating} out of 5</p>
                            </>
                            :
                            <p style={{fontFamily: 'Gilroy-Medium', color: 'rgb(50, 50, 50)'}}>No ratings yet!</p>
                        }
                    </div>
                    {
                        numOfReviews > 0
                        ?
                        <p style={{textAlign: 'center', marginTop: '.25rem', fontFamily: 'Gilroy-Medium', color: 'rgb(100, 100, 100)', fontSize: '.75rem'}}>{numOfReviews} client ratings</p>
                        :
                        null
                    }
                </div>
                <div className='distribution-container'>
                    <div className='distribution-row'>
                        <p id='star-type'>5 Stars</p>
                        <div className='distribution-meter'>
                            <div className='distribution' style={{width: numOfReviews > 0 ? `${(fiveStars / numOfReviews) * 100}%` : '0%'}}></div>
                        </div>
                        <p id="distribution-amount">{fiveStars}</p>
                    </div>
                    <div className='distribution-row'>
                        <p id='star-type'>4 Stars</p>
                        <div className='distribution-meter'>
                            <div className='distribution' style={{width: numOfReviews > 0 ? `${(fourStars / numOfReviews) * 100}%` : '0%'}}></div>
                        </div>
                        <p id="distribution-amount">{fourStars}</p>
                    </div>
                    <div className='distribution-row'>
                        <p id='star-type'>3 Stars</p>
                        <div className='distribution-meter'>
                            <div className='distribution' style={{width: numOfReviews > 0 ? `${(threeStars / numOfReviews) * 100}%` : '0%'}}></div>
                        </div>
                        <p id="distribution-amount">{threeStars}</p>
                    </div>
                    <div className='distribution-row'>
                        <p id='star-type'>2 Stars</p>
                        <div className='distribution-meter'>
                            <div className='distribution' style={{width: numOfReviews > 0 ? `${(twoStars / numOfReviews) * 100}%` : '0%'}}></div>
                        </div>
                        <p id="distribution-amount">{twoStars}</p>
                    </div>
                    <div className='distribution-row'>
                        <p id='star-type'>1 Stars</p>
                        <div className='distribution-meter'>
                            <div className='distribution' style={{width: numOfReviews > 0 ? `${(oneStars / numOfReviews) * 100}%` : '0%'}}></div>
                        </div>
                        <p id="distribution-amount">{oneStars}</p>
                    </div>
                </div>
                <p id="leave-rating-btn" onClick={() => setMode(1)}>Leave a rating</p>
                <p style={{fontSize: '10px', fontFamily: 'Gilroy-Regular', color: '#aeaeae', margin: 0, marginTop: '.25rem', width: '75%', textAlign: 'center'}}>*Coorly only allows those who have booked this business before to leave ratings</p>
            </div>
        );
    } else {
        return (
            <div className='ratings-card'>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '.25rem', width: '90%', justifyContent: 'space-between'}}>
                    <p id='leave-review-header'>Leave a review</p>
                </div>
                <div className='leave-review-container'>
                    <div className='leave-review-input-container'>
                        <p className='leave-review-input-label'>Rating</p>
                        <div style={{display: 'flex', flexDirection: 'row', marginTop: '.5rem'}}>
                            <div id='leave-rating-star' onClick={() => setLeaveRating(1)}>
                                <LikeIcon liked={leaveRating >= 1}/>
                            </div>
                            <div id='leave-rating-star' onClick={() => setLeaveRating(2)} >
                                <LikeIcon liked={leaveRating >= 2}/>
                            </div>
                            <div id='leave-rating-star' onClick={() => setLeaveRating(3)}>
                                <LikeIcon liked={leaveRating >= 3}/>
                            </div>
                            <div id='leave-rating-star' onClick={() => setLeaveRating(4)}>
                                <LikeIcon liked={leaveRating >= 4}/>
                            </div>
                            <div id='leave-rating-star' onClick={() => setLeaveRating(5)}>
                                <LikeIcon liked={leaveRating >= 5}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='leave-review-container'>
                    <div className='leave-review-input-container'>
                        <p className='leave-review-input-label'>Phone</p>
                        <input className="leave-review-input" placeholder='e.g. 5555555555' value={phone} onChange={(e) => setPhone(e.target.value)} maxLength={10}/>
                    </div>
                </div>
                <div className='leave-review-container'>
                    <div className='leave-review-input-container'>
                        <p className='leave-review-input-label'>Review</p>
                        <input className="leave-review-input" placeholder='Leave a review (Optional)' value={review} onChange={(e) => setReview(e.target.value)}/>
                    </div>
                </div>
                {
                    message
                    ?
                    <p style={{fontFamily: 'Gilroy-Medium', textAlign: 'center', width: '90%', fontSize: 14}}>{message}</p>
                    :
                    null
                }
                <div style={{display: 'flex', flexDirection: 'row', width: '90%', justifyContent: 'space-around'}}>
                    <div className='submit-review-btn' style={{backgroundColor: 'white'}} onClick={() => setMode(0)}>
                        <p style={{color: '#4169e1'}}>Cancel</p>
                    </div>
                    <div className='submit-review-btn' onClick={submitReview}>
                        <p>Submit</p>
                    </div>
                </div>
            </div>
        );
    }
}