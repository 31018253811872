import React, { useEffect, useState } from 'react';
import { formatDate, reFormatDate, addMinutesToTime } from '../helpers';
import { useParams, useNavigate } from 'react-router-dom';
import { Splashscreen } from './Splashscreen';
import { Link } from 'react-router-dom';
import '../stylesheets/confirmation.css'
import { cancelAppointmentReminder } from '../API/twillioreminders';

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

async function getClientName(client_id) {
    return fetch(`https://coorly-server.onrender.com/business/client/${client_id}`)
    .then((res) => res.json())
    .then((data) => {
        return (data[0].name);
    })
    
}

function sendBusinessPushNotification(name, business_id, date, start) {
    const message = `${name} has just cancelled their appointment on ${formatDate(reFormatDate(date.toLocaleDateString()))} @ ${start.replace(":00 ", " ")}`
    fetch(`https://coorly-server.onrender.com/getbusinesspushtokens/${business_id}`)
    .then((res) => res.json())
    .then((data) => {
        let tokens = [];
        for (let i = 0; i < data.length; i++) {
            tokens.push(data[i].token)
        }

        const formData = new FormData();

        formData.append('title', "Coorly Business");
        formData.append('body', message);
        formData.append('tokens', tokens);

        const config = {
            method: 'POST',
            body: formData,
        };

        fetch(`https://coorly-server.onrender.com/sendpushnotification/`, config);
    })
}

function sendBusinessAlert(name, business_id, date, start) {
    const message = `${name} has just cancelled their appointment on ${formatDate(reFormatDate(date.toLocaleDateString()))} @ ${start.replace(":00 ", " ")}`

    const formData = new FormData();

    formData.append('businessID', business_id);
    formData.append('type', 'canceled');
    formData.append('body', message);

    const config = {
        method: 'POST',
        body: formData,
    };

    fetch(`https://coorly-server.onrender.com/alerts/sendalert/`, config);
}

export function ConfirmationPage(props) {
    const navigate = useNavigate();

    const { business_id, appointmentId } = useParams();
    const [reminder_sid, setReminder_sid] = useState(null);
    const [client_id, setClient_Id] = useState(0);
    const [thumbnail, setThumbnail] = useState("");
    const [package_ids, setPackage_Ids] = useState("");
    const [business, setBusiness] = useState("");
    const [business_phone, setBusiness_Phone] = useState("");
    const [address, setAddress] = useState("");
    const [date, setDate] = useState(new Date());
    const [packages, setPackages] = useState(null);
    const [addons, setAddons] = useState([]);
    const [price, setPrice] = useState("");
    const [duration, setDuration] = useState(0);

    const start = new Date(date).toLocaleTimeString();
    const end = addMinutesToTime(new Date(date), duration).toLocaleTimeString();

    function getBusinessInfo() {
        fetch(`https://coorly-server.onrender.com/business/info/${business_id}`)
        .then((res) => res.json())
        .then((data) => {
            setBusiness(data[0][0].name);
            setBusiness_Phone(data[0][0].phone_number);
            if (data[1][0]) {
                setThumbnail(data[1][0].url)
            } else {
                setThumbnail('https://res.cloudinary.com/doopnoeyc/image/upload/v1682276487/logos/alt_coorly_logo_ddhd4b.png')
            }
        })
    }

    function getConfirmationInfo() {
        fetch(`https://coorly-server.onrender.com/getconfirmationinfo/${appointmentId}`)
        .then(res => res.json())
        .then(data => {
            if (data[0].status == -1) {
                navigate('/');
            }

            setAddress(data[0].address);
            setDate(new Date(data[0].date));
            setClient_Id(data[0].client_id);
            console.log(data[0].reminder_sid);
            setReminder_sid(data[0].reminder_sid);

            let duration = 0;
            let package_ids = [];
            let addonArr = [];

            for (let i = 0; i < data.length; i++) {
                duration += data[i].duration_minutes;
                package_ids.push(data[i].package_id);

                const nameArr = Object.keys(data[i].addons);
                const priceArr = Object.values(data[i].addons);

                for (let i = 0; i < nameArr.length; i++) {
                    addonArr.push({addon: nameArr[i], price: priceArr[i]});
                }
            }

            setDuration(duration);
            setAddons(addonArr);
            setPackage_Ids(package_ids)
        })
        .catch((err) => console.log(err))
    }

    async function getPackageInfo() {
        let packages = [];
        let price = 0;
        for (let i = 0; i < package_ids.length; i++) {
            await fetch(`https://coorly-server.onrender.com/business/packages/view/${package_ids[i]}`)
            .then(res => res.json())
            .then(data => {
                packages.push(data[0].name)
                price += data[0].price;
            })
            .catch((err) => console.log(err))
        }
        setPackages(packages);
        setPrice(price);
    }

    async function cancelAppointment() {
        const config = {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data'
            },
        };

        const name = await getClientName(client_id);

        fetch(`https://coorly-server.onrender.com/business/appointments/cancel/${appointmentId}&${client_id}`, config)
        .then(() => {
            cancelAppointmentReminder(reminder_sid);
            sendBusinessPushNotification(name, business_id, date, start);
            sendBusinessAlert(name, business_id, date, start)
        })
        .then(() => {
            navigate('/');
        })
    }

    useEffect(() => {
        getBusinessInfo();
        getConfirmationInfo();
    }, [])

    useEffect(() => {
        getPackageInfo();
    }, [package_ids])

    if (business && business_phone && date && address && packages && price) {
        return (
            <div className='confirmation-container'>
                <div id="header">
                    <img id='short-logo' src={process.env.PUBLIC_URL + '/media/New Coorly White Transparent.png'}/>
                </div>
                <div className='business-info'>
                    <img id='business-thumbnail' src={thumbnail}/>
                    <Link to={`coorly.app/business/${business_id}/${business.replaceAll(" ", "-")}`}>{business}</Link>
                </div>
                <div className='appointment-info'>
                    <div className='info-row'>
                        <p id='label'>Date:&nbsp;</p>
                        <p id='description'>{formatDate(reFormatDate(date.toLocaleDateString()))}</p>
                    </div>
                    <div className='info-row'>
                        <p id='label'>Time:&nbsp;</p>
                        <p id='description'>{start.replace(":00 ", " ")} - {end.replace(":00 ", " ")}</p>
                    </div>
                    <div className='info-row'>
                        <p id='label'>Address:&nbsp;</p>
                        <p id='description'>{address.split(',')[0]}, {address.split(',')[1]}</p>
                    </div>
                    <div className='info-row' style={{alignItems: 'flex-start'}}>
                        <p id='label'>Package(s):&nbsp;</p>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            {
                                packages.map((name) => {
                                    return (
                                        <p id='description'>{name}</p>
                                    )
                                })
                            }
                        </div>
                    </div>
                    {
                        Object.keys(addons)
                        ?
                        <div className='info-row'>
                            <p id='label'>Addon(s):&nbsp;</p>
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                {
                                    addons.map((x) => {
                                        return (
                                            <p id='description'>{x.addon}</p>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        :
                        null
                    }
                    <div className='info-row'>
                        <p id='label'>Total Price:&nbsp;</p>
                        <p id='description'>{formatter.format(price + addons.reduce((sum, addon) => sum + addon.price, 0))}</p>
                    </div>
                </div>
                <button className="cancel-button" onClick={cancelAppointment}>Cancel</button>
            </div>
        )
    } else {
        return (<Splashscreen />)
    }
}