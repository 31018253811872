export async function setAppointmentReminderSID(reminder_sid, appointment_id) {
    try {
        const formData = new FormData();
        
        formData.append('reminder_sid', reminder_sid);
        formData.append('appointment_id', appointment_id);
    
        const config = {
            method: 'PUT',
            body: formData,
        };
    
        return fetch(`https://coorly-server.onrender.com/business/appointments/update/reminderSid/`, config);
    } catch (err) {
        console.log(err);
    }
}