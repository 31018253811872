export async function scheduleDetailerHourBeforeReminder(name, phone, selectedTime) {
    try {
        const formData = new FormData();
        
        formData.append('phone', phone);
        formData.append('name', name);
        formData.append('datetime', String(selectedTime));
    
        const config = {
            method: 'POST',
            body: formData,
        };
    
        const res = await fetch(`https://coorly-server.onrender.com/schedule/businessremindertext/`, config);
        const data = await res.json();

        return (data.sid);
    } catch (err) {
        console.log(err);
    }
}

export async function cancelAppointmentReminder(reminder_sid) {
    try {
        const formData = new FormData();
        
        formData.append('reminder_sid', reminder_sid);
    
        const config = {
            method: 'DELETE',
            body: formData,
        };
    
        return fetch(`https://coorly-server.onrender.com/cancel/businessremindertext/`, config);
    } catch (err) {
        console.log(err);
    }
}