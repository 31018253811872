import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { CompanyBox } from '../CompanyBox';
import '../../stylesheets/searchresult.css'

const times = {
    15 : "15min",
    30 : "30min",
    45 : "45min",
    60 : "1 hour",
    75 : "1h 15min",
    90 : "1h 30min",
    105 : "1h 45min",
    120 : "2 hours",
    135 : "2h 15min",
    150 :"2h 30min",
    165 : "2h 45min",
    180 : "3 hours",
    195 : "3h 15min",
    210 : "3h 30min",
    225 : "3h 45min",
    240 : "4 hours",
    255 : "4h 15min",
    270 : "4h 30min",
    285 : "4h 45min",
    300 : "5 hours"
}

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

export function SearchResult(props) {

    const business_id = props.business_id;
    const [name, setName] = useState("");

    const [packages, setPackages] = useState([]);
    
    function getName() {
        fetch(`https://coorly-server.onrender.com/business/info/${business_id}`)
        .then((res) => res.json())
        .then((data) => {
            setName(data[0][0].name);
        })
        .catch(err => console.log("Companybox: ", err))
    }

    function getPackages() {
        fetch(`https://coorly-server.onrender.com/business/packages/${business_id}`)
        .then(response => response.json())
        .then((data) => {
            let arr = [];

            for (let i = 0; i < data.length; i++) {
                if (i == 3) break;
                arr.push({
                    name : data[i].name,
                    price : formatter.format(data[i].price),
                    time : times[data[i].avg_time]
                })
            }
        
            setPackages(arr);
        })
        .catch(err => console.log(err))
    }

    useEffect(() => {
        getName();
        getPackages();
    }, [business_id])

    if (packages.length > 0) {
        return (
            <div className='search-result-container'>
                <CompanyBox business_id={business_id} />
                <div className='search-services-container'>
                    <p id='header'>Top Packages <Link to={`/business/${business_id}/${name.replace(" ", "-")}`} style={{fontSize: '1rem', color: 'black', fontFamily: 'Gilroy-Medium', marginLeft: '1rem'}}>See All</Link></p>
                    {
                        packages.map((packages) => {
                            return(
                                <Link to={`/business/${business_id}/${name.replace(" ", "-")}`} className="search-service-container">
                                    <p>{packages.name} • {packages.price} • {packages.time}</p>
                                </Link>
                            )
                        })
                    }
                </div>
            </div>
        );
    }
}