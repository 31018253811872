import React, {useEffect, useState} from 'react';
import { useParams} from 'react-router-dom';
import { Footer } from '../components/Footer';
import { Header } from '../components/Header';
import { SearchResult } from '../components/elements/SearchResult';
import '../stylesheets/search.css'

export function SearchPage() {

    const { query, city } = useParams();
    const [results, setResults] = useState([]);
    const [count, setCount] = useState(0);

    useEffect(() => {
        const cityparam = city ?? "null";
        fetch(`https://coorly-server.onrender.com/search/${query}&${cityparam}`)
            .then(res => res.json())
            .then((data) => {
                let arr = [];
                for (let i = 0; i < data.length; i++) {
                    arr.push(data[i].business_id);
                }
                setCount(data.length);
                setResults(arr);
            })
            .catch(err => console.log(err))
    }, [query, city])

    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', overflowX: 'hidden'}}>
            <Header isSearch/>
            <div className='search-container'>
                <p style={{fontFamily: 'Gilroy-Bold', fontSize: '1.5rem', marginBottom: '1rem'}}>Results {query != 'null' ? `for: ${query}` : null} ({count})</p>
                <div className='results-list'>
                    {
                        results.length > 0
                        ?
                        results.map(x => {
                            return(
                                <SearchResult 
                                    business_id={x}
                                />
                            )
                        })
                        :
                        <p style={{fontSize: '1rem', fontFamily: 'Gilroy-Medium'}}>No Business Found!</p>
                    }
                </div>
            </div>
            <Footer />
        </div>
    )
}