import React, {useState, useEffect} from 'react';
import { GoogleMap, LoadScript, Marker, Circle } from '@react-google-maps/api';
import { Hours } from './Hours';
import { formatPhoneNumber } from '../helpers';
import { FiPhone, FiMonitor, FiMail } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import '../stylesheets/business.css';

function Map(props) {
    const containerStyle = {
        width: '100%',
        height: '200px',
    };

    let maxDistance = props.maxDistance;
    let coords = props.coords;
    const [center, setCenter] = useState({});

    useEffect(() => {
        if (coords != "") {
            setCenter({        
                lat: coords.lat,
                lng: coords.lon
            })
        }
    }, [coords])

    return (
        <LoadScript
            googleMapsApiKey="AIzaSyCfYps6Wi6CONLOU4JIBLRzqu-A_Szm7t8"
        >
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={7}
                clickableIcons={false}
                options={{disableDefaultUI: true, disableDoubleClickZoom: true}}
            >
                <Marker 
                    position={center}
                />
                <Circle
                    center={center}
                    radius={maxDistance * 1609.34}
                    options={{strokeColor: "rgba(255, 0, 0, .75)", fillColor: "rgba(255, 0, 0, .4)"}}
                />
            </GoogleMap>
        </LoadScript>
    )
}

export function Details(props) {

    const business_id = props.business_id;
    const phone = props.phone;
    const email = props.email;
    const website = props.website;
    const desc = props.desc;
    const coords = props.coords;
    const maxDistance = props.maxDistance;

    return (
        <div className='details-container'>
            <h2 style={{marginTop: 0, marginBottom: 16}}>Details</h2>
            <div className='location-container'>
                <Map coords={coords} maxDistance={maxDistance}/>
            </div>
            {
                desc != "" 
                ?
                <div className='about-container'>
                    <p id="bioHeader">About Us</p> 
                    <p id="bio">{desc}</p> 
                </div>
                :
                null
            }
            <div className='hours-container'>
                <p id="bioHeader">Business Hours</p>
                <Hours business_id={business_id}/>
            </div>
            <div className='hours-container'>
                <p id="bioHeader">Contact Us</p>
                {
                    website
                    ?
                    <Link to={website} className="banner-phone" style={{display: 'flex', flexDirection: 'row', marginTop: 4, marginBottom: 4}}>
                        <FiMonitor/>
                        <p style={{fontFamily: 'Gilroy-Regular', fontSize: '1rem', margin: 0, marginLeft: 8}}>{website}</p>
                    </Link>
                    :
                    null
                }
                <Link to={`tel:${phone}`} className="banner-phone" style={{display: 'flex', flexDirection: 'row', marginTop: 4, marginBottom: 4}}>
                    <FiPhone/>
                    <p style={{fontFamily: 'Gilroy-Regular', fontSize: '1rem', margin: 0, marginLeft: 8}}>{formatPhoneNumber(phone)}</p>
                </Link>
                <Link to={`mailto:${email}`} className="banner-phone" style={{display: 'flex', flexDirection: 'row', marginTop: 4, marginBottom: 4}}>
                    <FiMail/>
                    <p style={{fontFamily: 'Gilroy-Regular', fontSize: '1rem', margin: 0, marginLeft: 8}}>{email}</p>
                </Link>
            </div>
        </div>
    );
}