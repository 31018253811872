import React, {useState, useEffect} from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import Calendar from 'react-calendar';
import { getTimeDifferenceInMinutes, addMinutesToTime, getDayOfWeek, formatDate, reFormatDate, isValidAddress, validatePhoneNumber, getClientTimeZone, minutesBetweenDates, isValidPhoneNumber, toTitleCase } from '../helpers';
import Autocomplete from "react-google-autocomplete";
import { getDistanceBetweenTwoPoints } from 'calculate-distance-between-coordinates';
import { Splashscreen } from './Splashscreen';
import { Addon } from '../components/elements/Addon';
import { AdditionalService } from '../components/elements/AdditionalService';
import { setAppointmentReminderSID } from '../API/appointments';
import { scheduleDetailerHourBeforeReminder } from '../API/twillioreminders';
import '../stylesheets/checkout.css'
import 'react-calendar/dist/Calendar.css';

function generateTimes(startHour, endHour) {
    let end = new Date(2000, 0, 1, endHour, 0, 0);
  
    const times = [];
    let hour = new Date(2000, 0, 1, startHour, 0, 0);
  
    while (hour < end) {
      times.push(hour.toLocaleTimeString().replace(":00 ", " "));
      hour = addMinutesToTime(hour, 15)
    }
  
    return times;
}

function getTimeDifferenceInMinutesWeird(time1, time2) {
    function getTimeValue(time) {
        const parts = time.split(/[:\s]/);
        let hours = parseInt(parts[0], 10);
        const minutes = parseInt(parts[1], 10);
        const ampm = parts[2].toUpperCase();

        if (ampm === "PM" && hours !== 12) {
            hours += 12;
        } else if (ampm === "AM" && hours === 12) {
            hours = 0;
        }

        return hours * 60 + minutes;
    }

    const time1Value = getTimeValue(time1);
    const time2Value = getTimeValue(time2);

    const differenceInMinutes = Math.abs(time1Value - time2Value);
    return differenceInMinutes;
}

const morningTimes = generateTimes(0, 12);
const afternoonTimes = generateTimes(12, 17);
const eveningTimes = generateTimes(17, 24);

async function getBusinessUsersPhone(business_id) {
    return fetch(`https://coorly-server.onrender.com/business/userphones/${business_id}`)
    .then((res) => res.json())
    .then((data) => {
        return (data[0].phone_number);
    })
}

function sendGuestConfirmationText(phone, name, business, selectedDate, selectedTime, address, appointment_id, business_id) {
    const formData = new FormData();
        
    formData.append('phone', phone);
    formData.append('name', name);
    formData.append('business', business);
    formData.append('date', formatDate(reFormatDate(selectedDate.toLocaleDateString())));
    formData.append('time', new Date(selectedTime).toLocaleTimeString().replace(":00 ", " "));
    formData.append('address', address);
    formData.append('business_id', business_id);
    formData.append('appointment_id', appointment_id);

    const config = {
        method: 'POST',
        body: formData,
    };

    fetch(`https://coorly-server.onrender.com/sendguestconfirmationtext/`, config);
}

function sendBusinessPushNotification(name, business_id, selectedDate, selectedTime) {
    const message = `${name} has just scheduled an appointment for ${formatDate(reFormatDate(selectedDate.toLocaleDateString()))} @ ${new Date(selectedTime).toLocaleTimeString().replace(":00 ", " ")}`
    
    fetch(`https://coorly-server.onrender.com/getbusinesspushtokens/${business_id}`)
    .then((res) => res.json())
    .then((data) => {
        let tokens = [];
        for (let i = 0; i < data.length; i++) {
            tokens.push(data[i].token)
        }
        
        const formData = new FormData();

        formData.append('title', "Coorly for Businesses");
        formData.append('body', message);
        formData.append('tokens', tokens);

        const config = {
            method: 'POST',
            body: formData,
        };

        fetch(`https://coorly-server.onrender.com/sendpushnotification/`, config);
    });
}

function sendBusinessAlert(name, business_id, selectedDate, selectedTime) {
    const message = `${name} has just scheduled an appointment for ${formatDate(reFormatDate(selectedDate.toLocaleDateString()))} @ ${new Date(selectedTime).toLocaleTimeString().replace(":00 ", " ")}`

    const formData = new FormData();

    formData.append('businessID', business_id);
    formData.append('type', 'booked');
    formData.append('body', message);

    const config = {
        method: 'POST',
        body: formData,
    };

    fetch(`https://coorly-server.onrender.com/alerts/sendalert/`, config);
}

async function completeTask7(business_id) {
    const config = {
        method: 'PUT',
    };

    await fetch(`https://coorly-server.onrender.com/verification/task7/complete/${business_id}`, config);
}

function verificationCompletionCheck(business_id) {
    const config = {
        method: 'PUT',
    };

    fetch(`https://coorly-server.onrender.com/verification/complete/${business_id}`, config);
}

async function getClientId(business_id, name, phone) {
    return fetch(`https://coorly-server.onrender.com/business/client/check/businessid=${business_id}&phone=${phone}`)
    .then((res) => res.json())
    .then((data) => {
        if (data[0]) {
            return data[0].client_id;
        } else {

            const formData = new FormData();

            formData.append('business_id', business_id);
            formData.append('name', name);
            formData.append('phone', phone);

            const config = {
                method: 'POST',
                body: formData,
            };

            return fetch(`https://coorly-server.onrender.com/business/clients/add/`, config)
            .then((res) => res.json())
            .then((data) => {
                return data[1][0].client_id;
            })
        }
    })
}

async function setAppointment(parent_id, client_id, business_id, package_id, addon_info, selectedTime, duration, address, vehicle) {
    const formData = new FormData();

    formData.append('parent_id', parent_id);
    formData.append('client_id', client_id);
    formData.append('business_id', business_id);
    formData.append('package_id', package_id);
    formData.append('addons', JSON.stringify(addon_info));
    formData.append('date', new Date(selectedTime).toISOString());
    formData.append('duration_minutes', duration);
    formData.append('address', address);
    formData.append('vehicle', vehicle);

    const config = {
        method: 'POST',
        body: formData,
    };

    return fetch(`https://coorly-server.onrender.com/makeappointment/`, config)
    .then((res) => res.json())
    .then((data) => {
        return data[0].appointment_id
    })
}

function isTimeGreaterThan(time1, time2) {
    function getTimeValue(time) {
        const parts = time.split(/[:\s]/);
        let hours = parseInt(parts[0], 10);
        const minutes = parseInt(parts[1], 10);
        const ampm = parts[2].toUpperCase();

        if (ampm === "PM" && hours !== 12) {
            hours += 12;
        } else if (ampm === "AM" && hours === 12) {
            hours = 0;
        }

        return hours * 60 + minutes;
    }

    const time1Value = getTimeValue(time1);
    const time2Value = getTimeValue(time2);

    if (time1Value > time2Value) {
        return true;
    } else if (time2Value > time1Value) {
        return false;
    }
}

async function getDateCancelledStatus(business_id, date) {
    try {
        const res = await fetch(`https://coorly-server.onrender.com/date/status/business_id=${business_id}&date=${date}`);
        const data = await res.json();

        return (Boolean(data[0].cancelled_day_id));
    } catch (err) {
        //console.log(err);
    }
}

export function BookPage() {

    const navigate = useNavigate();
    const { business_id } = useParams();
    const { state } = useLocation();

    useEffect(() => {
        document.title = state.business_name;
    }, [])

    const [loading, setLoading] = useState(false);
    const [parentInfo, setParentInfo] = useState({});
    const [bookingInfoQueue, setBookingInfoQueue] = useState([]);

    const [package_id, setPackage_id] = useState(state.package_id);
    const [package_name, setPackage_name] = useState(state.package);
    const [package_price, setPackage_price] = useState(state.price);
    const [package_duration, setPackage_duration] = useState(state.avg_time);
    const [additional_packages, setAdditional_packages] = useState([]);

    const [packages, setPackages] = useState([]);
    const [vehicle_type, setVehicle_type] = useState("all");
    const [validTypes, setValidTypes] = useState([]);

    const [addons, setAddons] = useState([]);
    const [addon_info, setAddon_Info] = useState({});
    const [addon_duration, setAddon_Duration] = useState(0);

    const [isCancelled, setIsCancelled] = useState(false);
    const [hasSameDay, setHasSameDay] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [available, setAvailable] = useState([]);
    const [hours, setHours] = useState({});
    const [selectedTime, setSelectedTime] = useState(null);
    const [bookedTimes, setBookedTimes] = useState([]);
    const [remaining, setRemaining] = useState(1440); // 1440 = Number of minutes in a day

    const [name, setName] = useState("");
    const [address, setAddress] = useState("");
    const [phone, setPhone] = useState("");
    const [vehicle, setVehicle] = useState("");

    const [userCoords, setUserCoords] = useState({});
    const [message, setMessage] = useState("");
    const [error, setError] = useState(null);

    async function checkIfDateIsCancelled() {
        try {
            const isDateCancelled = await getDateCancelledStatus(business_id, reFormatDate(selectedDate.toLocaleDateString()));
            setIsCancelled(isDateCancelled);
        } catch (err) {
            console.log(err);
        }
    }

    function timeOverlapCheck(start, duration, booked) {
        const finish = addMinutesToTime(start, duration);
        const times = booked;

        let current = new Date(start);

        while (current <= finish) {
            const formattedCurrent = current.toLocaleTimeString();
            if (times.indexOf(formattedCurrent) != -1) {
                return (true);
            }
            current = addMinutesToTime(current, 15);
        }

        return (false);
    }

    function getAvailableTimes(start, end) {
        setSelectedTime(null);
        setAvailable([]);

        const duration = state.avg_time + addon_duration;
        if (start == null) { return }

        let times = [];
        const unavailableTimes = bookedTimes;

        const selectedArr = new Date(selectedDate).toLocaleDateString().split("/");

        let current = new Date(`${selectedArr[2]}-${selectedArr[0].padStart("2", 0)}-${selectedArr[1].padStart(2, "0")}T${String(start).split("T")[1]}`);
        const ending = addMinutesToTime(current, minutesBetweenDates(new Date(end), new Date(start)));

        while (current < ending) {
            let time = new Date(current).toLocaleTimeString();
            if (unavailableTimes.indexOf(time) == -1 && !timeOverlapCheck(current, duration, unavailableTimes)) {
                times.push({label: time.replace(":00 ", " "), value: current});
            }
            current = addMinutesToTime(current, 15);
        }
        setAvailable(times);
    }

    async function addAdditionalPackageToHeader(pack, price) {
        const json = {
            package: pack,
            price: price,
            vehicle: vehicle
        }

        setAdditional_packages([...additional_packages, json])
    }

    async function addInfoToQueue() {
        setMessage("");
        setError("");

        if (vehicle.length == 0) { 
            setError("You must enter a vehicle to add an additional service."); 
            return; 
        }
        
        const duration = package_duration + addon_duration;

        if (remaining < duration) {
            setError("No more time slots available for additional services. Either confirm your appointment, remove an addon, or pick a different time/day");
            return
        } else {
            setRemaining(remaining - duration);
        }

        const json = {
            package_id: package_id,
            addon_info: addon_info,
            selectedTime: selectedTime,
            duration: duration,
            vehicle: vehicle
        }

        if (bookingInfoQueue.length == 0) {
            const parentInfo = {
                selectedDate: selectedDate,
                selectedTime: selectedTime,
            }
            setParentInfo(parentInfo);
        }

        setBookingInfoQueue([...bookingInfoQueue, json]);
        setPackage_id(null);
        setVehicle("");
        setAddon_Info({});
        setAddon_Duration(0);
        setSelectedTime(addMinutesToTime(selectedTime, duration));
    }

    async function createSingleAppointment() {
        if (name && selectedTime && isValidAddress(address)) {
            setLoading(true);
            const business_phone = await getBusinessUsersPhone(business_id);

            const business_name = state.business_name;
            const duration = state.avg_time + addon_duration;

            try {
                const distance = getDistanceBetweenTwoPoints(userCoords, state.coords, 'miles');
                if (distance > state.maxDistance) { throw new Error("The address you have chosen is out of this business's operating range"); }

                await isValidPhoneNumber(phone);
                const client_id = await getClientId(business_id, name, phone);
                const appointment_id = await setAppointment(null, client_id, business_id, package_id, addon_info, selectedTime, duration, address, vehicle); 

                sendGuestConfirmationText(phone, name, business_name, selectedDate, selectedTime, address, appointment_id, business_id);
                sendBusinessPushNotification(name, business_id, selectedDate, selectedTime);
                sendBusinessAlert(name, business_id, selectedDate, selectedTime);
                const reminder_sid = await scheduleDetailerHourBeforeReminder(name, business_phone, selectedTime);
                setAppointmentReminderSID(reminder_sid, appointment_id);
                completeTask7(business_id)
                .then(() => verificationCompletionCheck(business_id));

                navigate(`/appointment/${business_id}/confirmation/${appointment_id}`);

            } catch (err) {
                setError(err.message);
            } finally {
                setMessage("");
                setLoading(false)
            }
        }            
        else {
            setError("Please ensure all information has been entered correctly")
        }
    }

    async function createMultipleAppointment() {
        setLoading(true);

        const business_phone = await getBusinessUsersPhone(business_id);
        const business_name = state.business_name;

        try {
            let parent_id = null;

            const distance = getDistanceBetweenTwoPoints(userCoords, state.coords, 'miles');
            if (distance > state.maxDistance) { throw new Error("The address you have chosen is out of this business's operating range"); }

            await isValidPhoneNumber(phone);
            const client_id = await getClientId(business_id, name, phone);

            for (let i = 0; i < bookingInfoQueue.length; i++) {
                const appointment_id = await setAppointment(
                    parent_id, 
                    client_id, 
                    business_id, 
                    address,
                    bookingInfoQueue[i].package_id, 
                    bookingInfoQueue[i].addon_info, 
                    bookingInfoQueue[i].selectedTime, 
                    bookingInfoQueue[i].duration, 
                    bookingInfoQueue[i].vehicle,
                ); 

                if (i == 0) {
                    parent_id = appointment_id;
                }
            }
            
            sendGuestConfirmationText(phone, name, business_name, parentInfo.selectedDate, parentInfo.selectedTime, address, parent_id, business_id);
            sendBusinessPushNotification(name, business_id, parentInfo.selectedDate, parentInfo.selectedTime);
            sendBusinessAlert(name, business_id, parentInfo.selectedDate, parentInfo.selectedTime);
            const reminder_sid = await scheduleDetailerHourBeforeReminder(name, business_phone, parentInfo.selectedTime);
            setAppointmentReminderSID(reminder_sid, parent_id);

            completeTask7(business_id)
            .then(() => verificationCompletionCheck(business_id));

            navigate(`/appointment/${business_id}/confirmation/${parent_id}`);

        } catch (err) {
            setError(err.message);
        } finally {
            setMessage("");
            setLoading(false)
        }
    }
    
    function getPackages() {
        fetch(`https://coorly-server.onrender.com/business/packages/${business_id}`)
        .then((res) => res.json())
        .then((data) => {
            let arr = [];
            let validTypes = new Set();
            for (let i = 0; i < data.length; i++) {
                arr.push(data[i]);
                validTypes.add(data[i].vehicle_type);
            }
            setPackages(arr);
            setValidTypes(Array.from(validTypes));
        })
        .catch(err => console.log(err))
    }

    function getAddons() {
        fetch(`https://coorly-server.onrender.com/business/addons/${business_id}`)
        .then(response => response.json())
        .then((data) => {
            setAddons(data);
        })
        .catch(err => console.log(err))
    }

    function addAddon(name, duration, price) {
        let json = addon_info;
        json[name] = price;

        setAddon_Info(json);
        setAddon_Duration(addon_duration + duration);
    }

    function removeAddon(name, duration) {
        let json = addon_info
        delete addon_info[name];

        setAddon_Info(json);
        setAddon_Duration(addon_duration - duration);
    }

    function checkAllInputs() {
        if (selectedTime == null) { setMessage("Select a time"); return; }
        if (name.trim() == "") { setMessage("Enter your name"); return; }
        if (!validatePhoneNumber(phone)) { setMessage("Enter a valid phone number"); return; }
        if (address == "") { setMessage("Enter your address"); return; }
        if (!isValidAddress(address)) { setMessage("Please enter a valid address"); return;}
                        
        setMessage("");
        setError("");
    }

    useEffect(() => {
        getAddons();
        fetch(`https://coorly-server.onrender.com/business/schedule/${business_id}`)
        .then((res) => res.json())
        .then((data) => {
            
            if (data[0].isSameDayAllowed) {
                setSelectedDate(new Date())
            } else {
                setSelectedDate(new Date(new Date().setDate(new Date().getDate() + 1)));
            }

            const hours = {
                'sunday' : [data[0].sundayStart, data[0].sundayEnd],
                'monday' : [data[0].mondayStart, data[0].mondayEnd],
                'tuesday' : [data[0].tuesdayStart, data[0].tuesdayEnd],
                'wednesday' : [data[0].wednesdayStart, data[0].wednesdayEnd],
                'thursday' : [data[0].thursdayStart, data[0].thursdayEnd],
                'friday' : [data[0].fridayStart, data[0].fridayEnd],
                'saturday' : [data[0].saturdayStart, data[0].saturdayEnd]
            }
            
            setHasSameDay(data[0].isSameDayAllowed);
            setHours(hours);
        })
    }, [])

    useEffect(() => {
        checkIfDateIsCancelled();
        if (selectedDate == null) { return }

        const date = selectedDate.toISOString().split('T')[0];
        const clientTimeZone = getClientTimeZone().replace("/", "-");

        fetch(`https://coorly-server.onrender.com/business/appointments/${business_id}&${clientTimeZone}&${date}`)
        .then(response => response.json())
        .then((data) => {
            let timeframes = [];
            let illogicalTimes = [];
            for (let i = 0; i < data.length; i++) {
                let times = [];
                let appt_date = new Date(data[i].date); //UTC date is pulled from server and should be converted to local
                let duration_minutes = data[i].duration_minutes;
                let end = addMinutesToTime(appt_date, duration_minutes); //Gets end of appointment date time by adding duration_minutes to start

                while (appt_date <= end) {
                    times.push(appt_date.toLocaleTimeString()); //Pushes only time
                    appt_date = addMinutesToTime(appt_date, 15);
                }
                timeframes.push(times)
            }
            for (let i = 0; i < timeframes.length - 1; i++) {
                let prevLast = timeframes[i][timeframes[i].length - 1];
                const nextFirst = timeframes[i + 1][0];
            
                if (getTimeDifferenceInMinutes(prevLast, nextFirst) <= state.avg_time + 15) {
                    while (prevLast < nextFirst) {
                        prevLast = addMinutesToTime(prevLast, 15);
                        illogicalTimes.push(prevLast);
                    }
                } else {
                    let openslots = getTimeDifferenceInMinutes(prevLast, nextFirst) / (state.avg_time + 15)

                    for (let i = 0; i < Math.floor(openslots); i++) {
                        prevLast = addMinutesToTime(prevLast, 15);
                    }
                    while (prevLast < nextFirst) {
                        prevLast = addMinutesToTime(prevLast, 15);
                        illogicalTimes.push(prevLast);
                    }
                }
            }
            timeframes.push(illogicalTimes);
            setBookedTimes(timeframes.flat(1));
        })
        .catch(err => console.log(err))
    }, [selectedDate])
    
    useEffect(() => {
        if (selectedDate == null) { return }

        if (Object.keys(hours).length != 0) {
            const day = getDayOfWeek(selectedDate);
            let start = hours[day][0];
            let end = hours[day][1];
            getAvailableTimes(start, end);
        }
    }, [selectedDate, bookedTimes, addon_duration])

    useEffect(() => {
        checkAllInputs();
    }, [selectedTime, name, address, phone, vehicle])

    useEffect(() => {
        getPackages();
    }, [parentInfo])

    useEffect(() => {
        if (!selectedTime) { return }

        const selected = selectedTime.toLocaleTimeString();

        for (let i = 0; i < bookedTimes.length; i++) {
            const time = bookedTimes[i];
            if (!isTimeGreaterThan(selected, time)) {
                setRemaining(getTimeDifferenceInMinutesWeird(selected, time) - package_duration - 15); // 15 is the gap
                return;
            }
        };

        setRemaining(1440);
    }, [selectedTime]);

    if (hasSameDay != null && selectedDate != null) {
        if (Object.keys(parentInfo).length == 0) {
            return (
                <div className='coorly-checkout'>
                    <Header name={state.business_name}/>
                    <div className='service-container'>
                        <p id="service">{state.package}{addon_info.length > 0 ? ` + ${addon_info.length} Addon(s)`: ""}</p>
                        <p id="price">${parseInt(state.price) + parseInt(Object.values(addon_info).reduce((a, b) => a + b, 0))}</p>
                    </div>
                    <hr />
                    <div id='big-screen-config'>
                        <Calendar
                            value={selectedDate}
                            onChange={(e) => setSelectedDate(e)}
                            defaultActiveStartDate={selectedDate}
                            minDate={hasSameDay ? new Date() : new Date(new Date().setDate(new Date().getDate() + 1))}
                            maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 1))}
                            calendarType='US'
                        />
                        <div className='time-container'>
                            {
                                available.length > 0 && !isCancelled
                                ? 
                                    <>
                                        <div className='sub-time-container'>
                                            <p className='time-frame'>Morning</p>
                                            <div className='time-scroll'>
                                                {available.map((time) => {
                                                    if (morningTimes.indexOf(time.label) != -1) {
                                                        return (
                                                            <div className='time-btn' id={selectedTime == time.value ? 'selected' : null} onClick={() => setSelectedTime(time.value) }>
                                                                <p className="time" id={selectedTime == time.value ? 'selected' : null}>{time.label}</p>
                                                            </div>
                                                        );
                                                    }
                                                })} 
                                            </div>
                                        </div>
                                        <div className='sub-time-container'>
                                            <p className='time-frame'>Afternoon</p>
                                            <div className='time-scroll'>
                                                {available.map((time) => {
                                                    if (afternoonTimes.indexOf(time.label) != -1) {
                                                        return (
                                                            <div className='time-btn' id={selectedTime == time.value ? 'selected' : null} onClick={() => setSelectedTime(time.value)}>
                                                                <p className="time" id={selectedTime == time.value ? 'selected' : null}>{time.label}</p>
                                                            </div>
                                                        );
                                                    }
                                                })} 
                                            </div>
                                        </div>
                                        <div className='sub-time-container'>
                                            <p className='time-frame'>Evening</p>
                                            <div className='time-scroll'>
                                                {available.map((time) => {
                                                    if (eveningTimes.indexOf(time.label) != -1) {
                                                        return (
                                                            <div className='time-btn' id={selectedTime == time.value ? 'selected' : null} onClick={() => setSelectedTime(time.value)}>
                                                                <p className="time" id={selectedTime == time.value ? 'selected' : null}>{time.label}</p>
                                                            </div>
                                                        );
                                                    }
                                                })} 
                                            </div>
                                        </div>
                                    </>
                                : 
                                <p style={{alignSelf: 'center', fontFamily: 'Gilroy-Medium'}}>There are no available times for the selected date</p>
                            }
                        </div>
                    </div>
                    <hr />
                    <div className='input-container'>
                        <div className='input-container-row'>
                            <div className='checkout-input-container' id="checkout-name">
                                <p className='checkout-input-label'>Name</p>
                                <input className="checkout-input" placeholder='e.g. Jack Frost' value={name} onChange={(e) => setName(e.target.value)}/>
                            </div>
                            <div className='checkout-input-container' id="checkout-phone">
                                <p className='checkout-input-label'>Phone Number</p>
                                <input className="checkout-input" id="checkout-phone-input" placeholder='e.g. 5555555555' value={phone} maxLength={10} onChange={(e) => setPhone(e.target.value)}/>
                            </div>
                        </div>
                        <div className='input-container-row'>
                            <div className='checkout-input-container' id="checkout-phone">
                                <p className='checkout-input-label'>Address</p>
                                <Autocomplete 
                                    apiKey='AIzaSyCfYps6Wi6CONLOU4JIBLRzqu-A_Szm7t8'
                                    onPlaceSelected={(place) => {setAddress(place.formatted_address); setUserCoords({lat: place.geometry.location.lat(), lon: place.geometry.location.lng()})}}
                                    className='checkout-input'
                                    placeholder='Enter your address'
                                    options={{types: 'street_address'}}
                                />
                            </div>
                            <div className='checkout-input-container' id="checkout-phone">
                                <p className='checkout-input-label'>Vehicle Make & Model</p>
                                <input className="checkout-input" id="checkout-phone-input" placeholder='e.g. 2018 Honda Civic' value={vehicle} maxLength={50} onChange={(e) => setVehicle(e.target.value)}/>
                            </div>
                        </div>
                        <p className='checkout-input-label' style={{width: '90%', alignSelf: 'center'}}>Pick your addons (Optional)</p>
                        <div className='addon-container'>
                            {
                                addons.length > 0
                                ?
                                addons.map((addon) => {
                                    return (
                                        <div
                                            onClick={() => {
                                                addon.name in Object(addon_info)
                                                ?
                                                removeAddon(addon.name, addon.avg_time)
                                                :
                                                addAddon(addon.name, addon.avg_time, addon.price)
                                            }}
                                        >
                                            <Addon isSelected={addon.name in Object(addon_info)} addon_id={addon.addon_id} business_id={business_id} name={`${addon.name}`} description={addon.description} price={addon.price} avgTime={addon.avg_time}/>
                                        </div>
                                    )
                                })
                                :
                                null
                            }
                        </div>
                    </div>
                    {
                        error
                        ?
                        <p style={{textAlign: 'center', width: '90%', marginBottom: 0, fontFamily: 'Gilroy-Regular'}}>{error}</p>
                        :
                        null
                    }
                    <div className='checkout-buttons-container'>
                        <button className="checkout-button" id="addServiceBtn" disabled={message != ""} onClick={addInfoToQueue}>{"Add another service/vehicle"}</button>
                        <button className="checkout-button" disabled={message != "" || loading} onClick={createSingleAppointment}>{message ? message : "Confirm Booking"}</button>
                    </div>
                    <Footer />
                </div>
            )
        } else {
            return (
                <div className='coorly-checkout'>
                    <Header name={state.business_name}/>
                    <div className='service-container'>
                        <p id="service">{state.package}{addon_info.length > 0 ? ` + ${addon_info.length} Addon(s)`: ""}</p>
                        <p id="price">${parseInt(state.price) + parseInt(Object.values(addon_info).reduce((a, b) => a + b, 0))}</p>
                    </div>
                    <hr />
                    {
                        additional_packages.length
                        ?
                        additional_packages.map((x) => {
                            return (
                                <>
                                    <div className='service-container' style={{marginTop: 0}}>
                                        <p id="service">{x.package}{addon_info.length > 0 ? ` + ${addon_info.length} Addon(s)`: ""} - {x.vehicle}</p>
                                        <p id="price">${parseInt(x.price) + parseInt(Object.values(addon_info).reduce((a, b) => a + b, 0))}</p>
                                    </div>
                                    <hr />
                                </>
                            )
                        })
                        :
                        null
                    }
                    <p className='checkout-input-label' style={{width: '90%', alignSelf: 'center'}}>Pick additional package</p>
                    <div className="packages-options-container" style={{width: '90%', alignSelf: 'center', margin: '1rem'}}>
                        {validTypes.map((type) => {
                            return (
                                <p className='vehicle-type-option' id={vehicle_type == type ? 'selected' : null} onClick={() => setVehicle_type(type)}>
                                    {type == 'all' ? toTitleCase(type) : `${toTitleCase(type)}s`}
                                </p>
                            )
                        })}
                    </div>
                    <div className='additional-services-container'>
                        {
                            packages.map((x) => { return (x.vehicle_type == vehicle_type || vehicle_type == 'all') && x.avg_time <= remaining }).indexOf(true) != -1
                            ?
                            packages.map((x) => {
                                if ((x.vehicle_type == vehicle_type || vehicle_type == 'all') && x.avg_time <= remaining) { 
                                    return(
                                        <div onClick={() => { setPackage_id(x.package_id); setPackage_name(x.name); setPackage_price(x.price); setPackage_duration(x.avg_time) }}>
                                            <AdditionalService isSelected={package_id == x.package_id} package_id={x.package_id} name={x.name} price={x.price} avgTime={x.avg_time} services={x.services} type={x.vehicle_type}/>
                                        </div>
                                    )
                                }
                            })
                            :
                            <p style={{alignSelf: 'center', fontFamily: 'Gilroy-Medium', marginTop: 0}}>There are no available timeslots for additional services. Either confirm your appointment or select a different time/date.</p>
                        }
                    </div>
                    <hr />
                    <div className='input-container'>
                        <div className='checkout-input-container' id="checkout-phone">
                            <p className='checkout-input-label'>Vehicle Make & Model</p>
                            <input className="checkout-input" id="checkout-phone-input" style={{width: '97.5%'}} placeholder='e.g. 2018 Honda Civic' value={vehicle} maxLength={50} onChange={(e) => setVehicle(e.target.value)}/>
                        </div>
                        <p className='checkout-input-label' style={{width: '90%', alignSelf: 'center'}}>Pick your addons (Optional)</p>
                        <div className='addon-container'>
                            {
                                addons.length > 0
                                ?
                                addons.map((addon) => {
                                    return (
                                        <div
                                            onClick={() => {
                                                addon.name in Object(addon_info)
                                                ?
                                                removeAddon(addon.name, addon.avg_time)
                                                :
                                                addAddon(addon.name, addon.avg_time, addon.price)
                                            }}
                                        >
                                            <Addon isSelected={addon.name in Object(addon_info)} addon_id={addon.addon_id} business_id={business_id} name={`${addon.name}`} description={addon.description} price={addon.price} avgTime={addon.avg_time}/>
                                        </div>
                                    )
                                })
                                :
                                null
                            }
                        </div>
                    </div>
                    {
                        error
                        ?
                        <p style={{textAlign: 'center', width: '90%', marginBottom: 0, fontFamily: 'Gilroy-Regular'}}>{error}</p>
                        :
                        null
                    }
                    <div className='checkout-buttons-container'>
                        <button className="checkout-button" id="addServiceBtn" disabled={error != "" || !package_id} onClick={() => { addAdditionalPackageToHeader(package_name, package_price, vehicle); addInfoToQueue() }}>{"Add another service/vehicle"}</button>
                        <button className="checkout-button" disabled={message != "" || loading} onClick={createMultipleAppointment}>Confirm Booking</button>
                    </div>
                    <Footer />
                </div>
            )
        }
    }
    else {
        return(<Splashscreen />)
    }
}