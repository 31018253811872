import React, {useState, useEffect} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Splashscreen } from './Splashscreen';
import { Details } from '../components/Details';
import { Header } from '../components/Header';
import { Images } from '../components/Images';
import { Footer } from '../components/Footer';
import { Reviews } from '../components/Reviews';
import { Service } from '../components/elements/Service';
import { FloatingDetails } from '../components/FloatingDetails';
import { toTitleCase } from '../helpers';
import '../stylesheets/business.css';
import { BusinessInfoBanner } from '../components/elements/BusinessInfoBanner';

export function BusinessPage() {

    const navigate = useNavigate();
    const { business_id, businessName } = useParams();

    useEffect(() => {
        document.title = businessName.replace("-", " ");
    }, [])

    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [website, setWebsite] = useState("");
    const [bio, setBio] = useState("");
    const [address, setAddress] = useState("");
    const [coords, setCoords] = useState("");
    const [maxDistance, setMaxDistance] = useState(0);
    const [instagram, setInstagram] = useState("");
    const [facebook, setFacebook] = useState("");
    const [type, setType] = useState("");

    const [packages, setPackages] = useState([]);
    const [vehicle_type, setVehicle_type] = useState("all");
    const [validTypes, setValidTypes] = useState([]);

    function addView() {
        const config = {
            method: 'PUT',
        };

        fetch(`https://coorly-server.onrender.com/business/metrics/views/increment/${business_id}`, config);
    }

    function getBusinessEmail() {
        fetch(`https://coorly-server.onrender.com/business/owner/email/${business_id}`)
        .then((res) => res.json())
        .then((data) => {
            setEmail(data[0].email);
        })
    }

    async function getBusinessInfo() {
        return fetch(`https://coorly-server.onrender.com/business/info/${business_id}`)
        .then((res) => res.json())
        .then((data) => {

            if (data[0].length == 0) { navigate(`/`); }

            const { phone_number, description, address, coordinates, max_travel_distance, primary_service, instagram, facebook, website } = data[0][0];

            setPhone(phone_number);
            setBio(description);
            setAddress(address);
            setCoords(coordinates);
            setMaxDistance(max_travel_distance);
            setType(primary_service);
            setInstagram(instagram);
            setFacebook(facebook);
            setWebsite(website);

            return ({
                business_name: businessName.replace("-", " "),
                business_address: address,
                coords: coordinates,
                maxDistance: max_travel_distance,
                type: primary_service
            });
        })
    }
    
    async function getPackages(info) {
        await fetch(`https://coorly-server.onrender.com/business/packages/${business_id}`)
        .then(response => response.json())
        .then((data) => {
            let arr = [];
            let validTypes = new Set();
            for (let i = 0; i < data.length; i++) {
                const component = <Service package_id={data[i].package_id} name={data[i].name} price={data[i].price} time={data[i].avg_time} services={data[i].services} type={data[i].vehicle_type} business_id={business_id} info={info}/>;
                const json = {
                    component: component,
                    type: data[i].vehicle_type
                }
                arr.push(json);
                validTypes.add(data[i].vehicle_type);
            }
            setPackages(arr);
            setValidTypes(Array.from(validTypes));
        })
        .catch(err => console.log(err))
    }

    useEffect(() => {
        addView();
        getBusinessEmail();
        getBusinessInfo()
        .then((info) => getPackages(info))
        .catch((err) => console.log(err));
    }, [])

    if (packages) {
        return (
            <div className='coorly-business'>
                <Header/>
                <div className='main-container'>
                    <div className='everything-else-container'>
                        <div style={{display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                            <BusinessInfoBanner business_id={business_id} name={businessName.replaceAll("-", " ")} phone={phone}/>
                        </div>
                        <Images business_id={business_id} />
                        <div className='services-container'>
                            <div className="packages-header-container">
                                <h2 style={{margin: 0}}>Packages</h2>
                                <div className="packages-options-container">
                                    {validTypes.map((type) => {
                                        return (
                                            <p className='vehicle-type-option' id={vehicle_type == type ? 'selected' : null} onClick={() => setVehicle_type(type)}>
                                                {type == 'all' ? toTitleCase(type) : `${toTitleCase(type)}s`}
                                            </p>
                                        )
                                    })}
                                </div>
                            </div>
                            {
                                packages.map((x) => {
                                    if (x.type == vehicle_type || vehicle_type == 'all') { return (x.component) }
                                })
                            }
                            <p style={{fontFamily: 'Gilroy-Regular', marginBottom: '.5rem', fontSize: '.75rem', color: '#B7B7B7', textAlign: 'center'}}>*Prices are subject to change at the discretion of the business</p>
                        </div>
                        <Reviews business_id={business_id}/>
                        <div className='mobile-details'>
                            <Details business_id={business_id} name={businessName.replace("_", " ")} phone={phone} email={email} website={website} desc={bio} address={address} coords={coords} maxDistance={maxDistance} instagram={instagram} facebook={facebook}/>
                        </div>
                    </div>
                    <FloatingDetails business_id={business_id} name={businessName.replace("_", " ")} phone={phone} email={email} website={website} desc={bio} address={address} coords={coords} maxDistance={maxDistance} instagram={instagram} facebook={facebook}/>
                </div>
                <Footer />
            </div>
        )
    }
    else {
        return (<Splashscreen />)
    }
}