import React, {useState, useEffect} from 'react';
import { Review } from './Review';
import { RatingsCard } from './elements/RatingsCard';
import { MdFavorite } from 'react-icons/md';
import { MdFavoriteBorder } from 'react-icons/md';
import '../stylesheets/business.css';

export function Reviews(props) {
    
    const business_id = props.business_id;

    const [reviews, setReviews] = useState([]);

    async function getReviews() {
        try {
            const res = await fetch(`https://coorly-server.onrender.com/business/reviews/${business_id}`);
            const data = await res.json();

            setReviews(data);
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        getReviews();
    }, [])

    return (
        <div className='reviews-container'>
            <h2>Reviews</h2>
            <RatingsCard business_id={business_id}/>
            <div className='reviews'>
                {
                    reviews.length > 0 
                    ?
                    reviews.map((review) => {
                        if (review.review) {
                            return (
                                <Review client_id={review.client_id} review={review.review} rating={review.rating} created_at={review.created_at}/>
                            )
                        }
                    })
                    :
                    <p style={{fontFamily: 'Gilroy-Medium', textAlign: 'center'}}>No reviews yet!</p>
                }
            </div>
        </div>
    );
}