import React from 'react';
import '../../stylesheets/addon.css';

const times = {
    15 : "15min",
    30 : "30min",
    45 : "45min",
    60 : "1 hour",
    75 : "1h 15min",
    90 : "1h 30min",
    105 : "1h 45min",
    120 : "2 hours",
    135 : "2h 15min",
    150 :"2h 30min",
    165 : "2h 45min",
    180 : "3 hours",
    195 : "3h 15min",
    210 : "3h 30min",
    225 : "3h 45min",
    240 : "4 hours",
    255 : "4h 15min",
    270 : "4h 30min",
    285 : "4h 45min",
    300 : "5 hours"
}

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

export function Addon(props) {

    const isSelected = props.isSelected;
    const name = props.name;
    const price = props.price;
    const time = props.avgTime;
    const description = props.description

    return (
        <div className='addon-box' style={{borderBottomColor: isSelected ? '#4CAF50' : '#4169e1'}}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                <div>
                    <p id='addon-name'>{name}</p>
                    <p id='addon-name'>{formatter.format(price)} • +{times[time]}</p>
                </div>
            </div>
        </div>
    );
}