export function convertToSQLDateTime(date) {
    const SQLDateTime = String(date).replace("T", " ")
                            .replace("Z", "")
                            .substring(0, 19);
  
    return(SQLDateTime);
}

export function isValidAddress(address) {
    return (address.split(",").length >= 4)
}

export function validatePhoneNumber(phoneNumber) {
    if (typeof parseInt(phoneNumber) == "number") {
        return (phoneNumber.toString().length == 10);
    }
    return false;
}

export async function isValidPhoneNumber(phone) {
    return fetch(`https://coorly-server.onrender.com/twillio/verifynumber/${phone}`)
    .then((res) => res.json())
    .then((data) => {
        if (!data.valid) { throw new Error("The phone number you have entered is invalid") };
    })
}

export function getClientTimeZone() {
    return (Intl.DateTimeFormat().resolvedOptions().timeZone)
}

export function formatTime(time) {
    if (time == null) {
        return 'Closed';
    }

    let arr = String(time).split(":");
    let hour = (arr[0] % 12);
    let suffix = arr[0] <= 12 ? "AM" : "PM";

    return (`${hour == 0 ? "12" : hour}:${arr[1] == 0 ? "00" : arr[1]} ${suffix}`)
}

export function getTimeDifferenceInMinutes(time1, time2) {
    // Convert times to Date objects with the same date to calculate difference
    const date1 = new Date(`2000-01-01T${time1}`);
    const date2 = new Date(`2000-01-01T${time2}`);
    
    // Calculate difference in minutes
    const diffInMs = Math.abs(date2 - date1);
    const diffInMinutes = Math.floor(diffInMs / (1000 * 60));
    
    return diffInMinutes;
}

export function minutesBetweenDates(endDate, startDate) {
    const diff = endDate.getTime() - startDate.getTime();

    return (diff / 60000);
}

export function reFormatDate(date) {
    let arr = date.split("/");
    return (`${arr[2]}-${String(arr[0]).padStart('2', '0')}-${String(arr[1]).padStart('2', '0')}`);
}

export function addMinutesToTime(date, minutesToAdd) {
    const dateObj = new Date(date);
    return new Date(dateObj.getTime() + minutesToAdd*60000);
}

export function getDayOfWeek(date) {
    const daysOfWeek = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
    const dateObj = new Date(date);
    const dayOfWeek = daysOfWeek[dateObj.getDay()];
    return dayOfWeek;
}

export function formatDate(dateString) {
    // Split the date string into its component parts
    const dateParts = dateString.split('-');
  
    // Create an array of month names
    const monthNames = [
      'January', 'February', 'March', 'April',
      'May', 'June', 'July', 'August', 'September',
      'October', 'November', 'December'
    ];
  
    // Get the month number and name
    const monthIndex = parseInt(dateParts[1], 10) - 1;
    const monthName = monthNames[monthIndex];
  
    // Get the day and year
    const day = dateParts[2];
    const year = dateParts[0];
  
    // Return the formatted date string
    return monthName + ' ' + day + ', ' + year;
}

export function formatPhoneNumber(phone) {
    let phoneString = phone.toString();
    return `(${phoneString.slice(0, 3)}) ${phoneString.slice(3, 6)}-${phoneString.slice(6)}`;
}

export function toTitleCase(str) {
    return str.replace(/\w\S*/g, function(txt){
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}