import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../../stylesheets/service.css';
import { toTitleCase } from '../../helpers';
import { FiChevronsRight } from 'react-icons/fi';

const times = {
    15 : "15min",
    30 : "30min",
    45 : "45min",
    60 : "1 hour",
    75 : "1h 15min",
    90 : "1h 30min",
    105 : "1h 45min",
    120 : "2 hours",
    135 : "2h 15min",
    150 :"2h 30min",
    165 : "2h 45min",
    180 : "3 hours",
    195 : "3h 15min",
    210 : "3h 30min",
    225 : "3h 45min",
    240 : "4 hours",
    255 : "4h 15min",
    270 : "4h 30min",
    285 : "4h 45min",
    300 : "5 hours"
}

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

export function Service(props) {

    const business_id = props.business_id;
    const package_id = props.package_id;
    const name = props.name
    const price = props.price;
    const time = props.time;
    const services = props.services.split("|");
    const type = props.type;

    const info = {
        ...props.info,
        package: name,
        package_id: package_id,
        price: price,
        avg_time: time,
    }

    return (
        <div className='service-box'>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                <div>
                    <p id='service-name' style={{fontSize: '1.1rem', fontFamily: 'Gilroy-Heavy'}}>{name}</p>
                    <p id='service-name' style={{fontFamily: 'GIlroy-Bold'}}>Starting at: {formatter.format(price)}</p>
                    <p id='service-name'>{times[time]} • {type == "all" ? "All Vehicles" : `${toTitleCase(type)}s`}</p>
                </div>
                <div>
                    <Link id="see-times-btn" to={`/business/${business_id}/book`} state={info}>BOOK</Link>
                </div>
            </div>
            <p id='service-price'>What's Included:</p>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                <div style={{width: '100%'}}>
                    <ul>
                        {
                            services.map((service) => {
                                return(
                                    <li id='service-desc'>{service}</li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
        </div>
    );
}