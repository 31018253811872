import React from 'react';
import '../../stylesheets/locationservicesbanner.css';

export function LocationServicesBanner(props) {
    return (
        <div className='location-services-banner'>
            <div>
                <p id='headertext'>What's nearby</p>
                <p id='subtext'>Want to see mobile detailers working in your area?</p>
                <p id='subtext'>Turn on location services and let's make it happen!</p>
                <button onClick={props.getLocation}>
                    Search near me
                </button>
            </div>    
            <img style={{width: '7rem'}} src={process.env.PUBLIC_URL + '/media/pngfind.com-travel-icon-png-2474784.png'}/>
        </div>
    );
}