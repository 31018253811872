import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import { MdSearch, MdLocationPin } from 'react-icons/md';
import '../stylesheets/searchbar.css'

export function SearchBar() {

    const [query, setQuery] = useState("");
    const [city, setCity] = useState("");

    const link = `/search/${query.trim() != "" ? query : "null"}/${city.trim()}`;

    return (
        <div className='searchbar-container'>
            <div className='search-inputs-container'>
                <div className='searchbar-query-container'>
                    <MdSearch size={26}/>
                    <input className="searchbar-query" type='text' placeholder="Find A Business..." value={query} onChange={(e) => setQuery(e.target.value)}/>
                </div>
                <div className='searchbar-query-container' id='location-container'>
                    <MdLocationPin size={26}/>
                    <input className="searchbar-query" id='location' type='text' placeholder="City (Optional)" value={city} onChange={(e) => setCity(e.target.value)}/>
                </div>
            </div>
            <Link to={query.trim() != "" || city.trim() != "" ? link : null} id="search-btn">
                <MdSearch size={28} color='black'/>
            </Link>
        </div>
    );
}