import React, {useState, useEffect} from 'react';
import { MdStar } from 'react-icons/md';
import { MdStarBorder } from 'react-icons/md';
import TimeAgo from 'javascript-time-ago'
import { getClientName } from '../API/clients';
import '../stylesheets/review.css';

function LikeIcon(props) {
    const liked = <MdStar color='rgb(255, 202, 82)' size={20}/>
    const unliked = <MdStarBorder color='rgb(255, 202, 82)' size={20}/>

    let status = props.liked ? liked : unliked;

    return (status);
}

/**
 * 
 * @param {{client_id: Number, review: String, rating: Number, created_at: Date}} props 
 * @returns 
 */
export function Review(props) {

    const { client_id, review, rating, created_at } = props; 

    const [name, setName] = useState("");
    const [ratingsArr, setRatingsArr] = useState([]);

    async function setClientName() {
        try {
            const client_name = await getClientName(client_id);
            setName(client_name);
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        setClientName();
    }, [])

    useEffect(() => {
        let arr = [null, null, null, null, null];
        arr.fill(<LikeIcon liked />, 0, rating);
        arr.fill(<LikeIcon />, rating, 5);

        setRatingsArr(arr);
    }, [])

    const timeAgo = new TimeAgo('en-US');

    if (name) {
        return (
            <div className="review-container">
                <div className='identifier-container'>
                    <img src='https://res.cloudinary.com/doopnoeyc/image/upload/v1682278294/profile_pictures/placeholder_avi_zt0gky.png'/>
                    <div>
                        <p id='name'>{name}</p>
                        <p id='upload-time'>{timeAgo.format(new Date(created_at))}</p>
                    </div>
                </div>
                <div className='other-container'>
                    {ratingsArr}
                    <p id='review'>{review}</p>
                </div>
            </div>
        );
    }
}